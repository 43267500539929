<script>
import financingService from '@/services/Financing/financingService';
import ordersService from '@/services/Orders/ordersService';

import { CWLTipoPagamento } from '@/store/cwl_enums';

import Vue from 'vue';

import QrcodeVue from 'qrcode.vue';

import moment from 'moment';

import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

import VueClipboards from 'vue-clipboards';
Vue.use(VueClipboards);

import { VTooltip } from 'v-tooltip';
Vue.directive('tooltip', VTooltip);

import settingsService from '@/services/Settings/settingsService';

import hubspotService from '@/services/HubSpot/hubspotService';

export default {
  components: {
    QrcodeVue
  },
  data() {
    return {
      modalidade: process.env.VUE_APP_CHECKOUT,
      financing: {
        totais: {}
      },
      order: {},
      isLoading: true,
      envPath: `${process.env.VUE_APP_ROOT_PORTAL}login`,
      canInvest: true,

      CWLTipoPagamento: CWLTipoPagamento,

      nome: '',
      email: '',
      cpf: '',
      valor: 0,
      nascimento: '',
      telefone: '',
      numeroCartao: '',
      nomeCartao: '',
      validadeCartao: '',
      codigoVerificadorCartao: '',
      doacaoPrivada: false,
      aceiteTermos: false,

      boletoActive: '',
      cartaoActive: '',

      settings: {},

      titleButtonContribuir: 'Contribuir',
      formDisabled: false,

      pessoa: {
        email: {},
        telefone: {},
        documento: {},
        dados_complementares: {},
        caracteristicas: []
      },

      linkToCodigoBarras: '',
      openBoletoURL: '',
      showTooltipCodigoBarrasCopiado: false,
      showAuxiliarTooltipCodigoBarrasCopiado: false,

      valorParaPagar: ' - - ',
      bancoTED: "",
      bancoCodigoTED: "",
      agenciaTED: '',
      contaTED: '',
      cnpjTED: '',
      nomeTED: '',

      qrCodeExpiration: '',
      pixKey: '',
      pixCopiaCola: '',
      showTooltipPixCopiado: false
    };
  },
  computed: {
    orderId() {
      return this.$route.params.oid;
    },
    loggedInUser() {
      return this.$store.getters.isLoggedIn;
    },
    loggedPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  async mounted() {
    this.financing = this.$CWLNewFinanciamentoPadrao();
    this.getSettings();
    await this.get();
  },
  methods: {
    async getSettings() {
      this.settings = await settingsService.getPrivateSettings();
      this.cnpjTED = this.settings.cnpj;
      this.nomeTED = this.settings.companyName;
    },
    statusDescription(status) {
      return this.$CWLFinancingStatus(status);
    },
    async calculosDaCampanha(financ) {
      return this.$CWLCampanhaCalculos(financ);
    },
    async get() {
      const orderDetail = await ordersService.getOrder(this.orderId);
      if (orderDetail.exists) {
        this.order = orderDetail.data();
        this.order.id = orderDetail.id;
        
        /**
         * PIX
         */
        this.qrCodeExpiration = moment(this.order.pix_expiredAt).format("DD/MM/YYYY HH:m:ss");
        this.pixKey = this.order.pix_key;
        this.pixCopiaCola = this.order.pix_qrCode;
        this.linkToCodigoBarras = this.order.boleto_codigo_barras
          ? this.order.boleto_codigo_barras
          : '';
        this.openBoletoURL = this.order.boleto_link
          ? this.order.boleto_link
          : '';

        if (this.order.item && this.order.item.value) {
          let valorParaPagarFormatado = parseFloat(
            this.order.item.value
          )
            .toFixed(2)
            .replace('.', ',');
          this.valorParaPagar =
            'R$ ' +
            valorParaPagarFormatado
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        }

        this.financing = await financingService.findById(
          this.order.financing_id
        );

        if ( this.financing.dados_bancarios ) {
          this.bancoTED = this.financing.dados_bancarios.banco ? this.financing.dados_bancarios.banco.name : "";
          this.bancoCodigoTED = this.financing.dados_bancarios.banco.code ? this.financing.dados_bancarios.banco.code : "";
          this.agenciaTED = this.financing.dados_bancarios.agencia_numero ? this.financing.dados_bancarios.agencia_numero : '';
          this.contaTED = this.financing.dados_bancarios.conta_numero ? this.financing.dados_bancarios.conta_numero : '';

          if ( this.financing.dados_bancarios.agencia_digito ) {
            this.agenciaTED = this.agenciaTED + "-" + this.financing.dados_bancarios.agencia_digito;
          }
          if ( this.financing.dados_bancarios.conta_digito ) {
            this.contaTED = this.contaTED + "-" + this.financing.dados_bancarios.conta_digito;
          }
        }

        this.isLoading = false;

        try {
          hubspotService.atualizarContatoAPI(this.loggedPessoa.pessoa_id, "");
        }
        catch (error) {
          console.log(error);
        }

        await this.calculosDaCampanha(this.financing);
      }
    },

    verBoleto() {
      window.open(this.openBoletoURL, '_blank');
    },

    showTooltipHandler() {
      this.showTooltipCodigoBarrasCopiado = true;

      setTimeout(
        function() {
          this.showTooltipCodigoBarrasCopiado = false;
        }.bind(this),
        1000
      );
    },

    showTooltipPixHandler() {
      this.showTooltipPixCopiado = true;

      setTimeout(
        function() {
          this.showTooltipPixCopiado = false;
        }.bind(this),
        1000
      );
    },

    showAuxiliarTooltipHandler() {
      this.showAuxiliarTooltipCodigoBarrasCopiado = true;

      setTimeout(
        function() {
          this.showAuxiliarTooltipCodigoBarrasCopiado = false;
        }.bind(this),
        1000
      );
    }
  }
};
</script>

<template>
  <div class="project-detail">
    <div class="row" v-if="financing.id">
      <div class="col-md-8">
        <div
          class="card"
          style="margin-left: 20px;"
          v-if="
            order.tipo_pagamento == CWLTipoPagamento.CartaoCredito &&
              modalidade == 'doacao'
          "
        >
          <div class="card-body">
            <div class="alert alert-success" role="alert">
              <h5
                class="card-subtitle text-muted text-center"
                style="color: #fff !important; margin-top: 0px;"
              >
                OBRIGADO POR CONTRIBUIR COM ESTA CAUSA!
              </h5>
            </div>
            <br />
            <p class="card-text">
              O pagamento foi confirmado e a sua contribuição já foi registrada.
              <br />
              <br />
              Valor: <b style="color: #00bf9a;">{{ valorParaPagar }}</b>
            </p>

            <br />

            <small class="form-text text-muted text-right"
              ><i
                >Também enviamos estas informações para o seu e-mail.</i
              ></small
            >
          </div>
        </div>

        <div
          class="card"
          style="margin-left: 20px;"
          v-if="
            order.tipo_pagamento == CWLTipoPagamento.CartaoCredito &&
              modalidade == 'equity'
          "
        >
          <div class="card-body">
            <div class="alert alert-success" role="alert">
              <h5
                class="card-subtitle text-muted text-center"
                style="color: #fff !important; margin-top: 0px;"
              >
                OBRIGADO PELO SEU APORTE!
              </h5>
            </div>
            <br />
            <p class="card-text">
              O pagamento foi confirmado e o seu aporte já foi registrado.
              <br />
              <br />
              Valor: <b style="color: #00bf9a;">{{ valorParaPagar }}</b>
            </p>

            <br />

            <small class="form-text text-muted text-right"
              ><i
                >Também enviamos estas informações para o seu e-mail.</i
              ></small
            >
          </div>
        </div>

        <div
          class="card"
          style="margin-left: 20px;"
          v-if="order.tipo_pagamento == CWLTipoPagamento.Reservado"
        >
          <div class="card-body">
            <div class="alert alert-success" role="alert">
              <h5
                class="card-subtitle text-muted text-center"
                style="color: #fff !important; margin-top: 0px;"
              >
                OBRIGADO POR RESERVAR SEU APORTE!
              </h5>
            </div>
            <br />
            <p class="card-text">
              A reserva foi confirmada com sucesso!
              <br />
              Você receberá por e-mail os dados bancários para realizar a TED.
              <br />
              <br />
              Valor: <b style="color: #00bf9a;">{{ valorParaPagar }}</b>
            </p>

            <br />

            <small class="form-text text-muted text-right"
              ><i
                >Também enviamos estas informações para o seu e-mail.</i
              ></small
            >
          </div>
        </div>

        <div
          class="card"
          style="margin-left: 20px;"
          v-if="
            order.tipo_pagamento == CWLTipoPagamento.Boleto &&
              modalidade == 'doacao'
          "
        >
          <div class="card-body">
            <div class="alert alert-success" role="alert">
              <h5
                class="card-subtitle text-muted text-center"
                style="color: #fff !important; margin-top: 0px;"
              >
                OBRIGADO POR SEU INVESTIMENTO!
              </h5>
            </div>
            <!-- <h6 class="card-subtitle text-muted text-center" style="font-size: 15px; margin-top: 5px;"><b>OBRIGADO POR CONTRIBUIR COM ESTA CAUSA</b></h6> -->
            <br />
            <p class="card-text">
              Pague o boleto agora ou até a data do vencimento para confirmar
              sua contribuição.
              <br />
              <br />
              Clique em
              <b
                style="cursor: pointer;"
                v-tooltip="{
                  content: 'Copiado!',
                  trigger: 'manual',
                  show: showAuxiliarTooltipCodigoBarrasCopiado
                }"
                v-clipboard="linkToCodigoBarras"
                @success="showAuxiliarTooltipHandler"
              >
                Copiar código de barras</b
              >, abra o aplicativo do seu banco e efetue o pagamento.
              <br />
              <br />
              Valor: <b style="color: #00bf9a;">{{ valorParaPagar }}</b>
            </p>

            <hr style="border-top-color: #6c757d; opacity: 0.1;" />

            <h6 class="card-subtitle text-muted text-center">
              {{ linkToCodigoBarras }}
            </h6>
            <br />
            <p
              class="card-text text-muted text-center"
              style="cursor: pointer; color: #00bf9a !important; text-decoration: underline;"
              v-tooltip="{
                content: 'Copiado!',
                trigger: 'manual',
                show: showTooltipCodigoBarrasCopiado
              }"
              v-clipboard="linkToCodigoBarras"
              @success="showTooltipHandler"
            >
              <b>Copiar código de barras</b>
            </p>

            <hr style="border-top-color: #6c757d; opacity: 0.1;" />

            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <div
                  class="alert alert-success"
                  role="alert"
                  style="cursor: pointer; background-color: #17a2b8;"
                  @click="verBoleto"
                >
                  <h5
                    class="card-subtitle text-muted text-center"
                    style="color: #fff !important; margin-top: 0px;"
                  >
                    Visualizar Boleto
                  </h5>
                </div>
              </div>
              <div class="col-md-4"></div>
            </div>

            <small class="form-text text-muted text-right"
              ><i
                >Também enviamos estas informações para o seu e-mail.</i
              ></small
            >
            <br />
          </div>
        </div>

        <div
          class="card"
          style="margin-left: 20px;"
          v-if="
            order.tipo_pagamento == CWLTipoPagamento.Boleto &&
              modalidade == 'equity'
          "
        >
          <div class="card-body">
            <div class="alert alert-success" role="alert">
              <h5
                class="card-subtitle text-muted text-center"
                style="color: #fff !important; margin-top: 0px;"
              >
                OBRIGADO PELO SEU APORTE!
              </h5>
            </div>
            <!-- <h6 class="card-subtitle text-muted text-center" style="font-size: 15px; margin-top: 5px;"><b>OBRIGADO POR CONTRIBUIR COM ESTA CAUSA</b></h6> -->
            <br />
            <p class="card-text">
              Pague o boleto agora ou até a data do vencimento para confirmar
              seu aporte.
              <br />
              <br />
              Clique em
              <b
                style="cursor: pointer;"
                v-tooltip="{
                  content: 'Copiado!',
                  trigger: 'manual',
                  show: showAuxiliarTooltipCodigoBarrasCopiado
                }"
                v-clipboard="linkToCodigoBarras"
                @success="showAuxiliarTooltipHandler"
              >
                Copiar código de barras</b
              >, abra o aplicativo do seu banco e efetue o pagamento.
              <br />
              <br />
              Valor: <b style="color: #00bf9a;">{{ valorParaPagar }}</b>
            </p>

            <hr style="border-top-color: #6c757d; opacity: 0.1;" />

            <h6 class="card-subtitle text-muted text-center">
              {{ linkToCodigoBarras }}
            </h6>
            <br />
            <p
              class="card-text text-muted text-center"
              style="cursor: pointer; color: #00bf9a !important; text-decoration: underline;"
              v-tooltip="{
                content: 'Copiado!',
                trigger: 'manual',
                show: showTooltipCodigoBarrasCopiado
              }"
              v-clipboard="linkToCodigoBarras"
              @success="showTooltipHandler"
            >
              <b>Copiar código de barras</b>
            </p>

            <hr style="border-top-color: #6c757d; opacity: 0.1;" />

            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <div
                  class="alert alert-success"
                  role="alert"
                  style="cursor: pointer; background-color: #17a2b8;"
                  @click="verBoleto"
                >
                  <h5
                    class="card-subtitle text-muted text-center"
                    style="color: #fff !important; margin-top: 0px;"
                  >
                    Visualizar Boleto
                  </h5>
                </div>
              </div>
              <div class="col-md-4"></div>
            </div>

            <small class="form-text text-muted text-right"
              ><i
                >Também enviamos estas informações para o seu e-mail.</i
              ></small
            >
            <br />
          </div>
        </div>

        <div
          class="card"
          style="margin-left: 20px;"
          v-if="
            order.tipo_pagamento == CWLTipoPagamento.TED &&
              modalidade == 'equity'
          "
        >
          <div class="card-body">
            <div class="alert alert-success" role="alert">
              <h5
                class="card-subtitle text-muted text-center"
                style="color: #fff !important; margin-top: 0px;"
              >
                OBRIGADO PELO SEU APORTE!
              </h5>
            </div>
            <!-- <h6 class="card-subtitle text-muted text-center" style="font-size: 15px; margin-top: 5px;"><b>OBRIGADO POR CONTRIBUIR COM ESTA CAUSA</b></h6> -->
            <br />
            <p class="card-text">
              Efetue uma TED no valor <b style="color: #00bf9a;">{{ valorParaPagar }}</b> para os dados bancários abaixo:
              <br />
              <br />
              <!-- <b>Instituição Bancária:</b> {{ bancoTED }}<br>
              <b>Banco Número:</b> {{ bancoCodigoTED }}<br>
              <b>Agência:</b> {{ agenciaTED }}<br>
              <b>Conta:</b> {{ contaTED }}<br>
              <b>CNPJ:</b> {{ cnpjTED }}<br>
              <b>Nome:</b> {{ nomeTED }}<br> -->
              <b>Banco Itaú:</b>341 <br>
              <b>Agência:</b> 1268<br>
              <b>Conta Corrente:</b> 96814-4<br>
              <b>CNPJ:</b> 42.697.069/0001-66<br>
              <b>AJX CAPITAL INVESTIMENTOS S.A.</b><br>
              <b>Chave PIX:</b> 42.697.069/0001-66<br>
              <br />
              <br />
              Após a operação, por favor envie o comprovante para comprovante@ajxcapital.com.br.
            </p>

            <hr style="border-top-color: #6c757d; opacity: 0.1;" />

            <small class="form-text text-muted text-right"
              ><i
                >Também enviamos estas informações para o seu e-mail.</i
              ></small
            >
            <br />
          </div>
        </div>

        <div
          class="card"
          style="margin-left: 20px;"
          v-if="
            order.tipo_pagamento == 'PIX'
          "
        >
          <div class="card-body">
            <div class="alert alert-success" role="alert">
              <h5
                class="card-subtitle text-muted text-center"
                style="color: #fff !important; margin-top: 0px;"
              >
                OBRIGADO PELO SEU APORTE!
              </h5>
            </div>
            <br />
            <p class="card-text" v-if="pixCopiaCola">
              Faça o pagamento com Pix utilizando este QR Code ou Código Copia e Cola <b>em até 7 dias</b>.
              <!-- Faça o pagamento com Pix utilizando esta chave pix: 42.697.069/0001-66 -->
            </p>

            <br />

            <div style="text-align: center;">
              <qrcode-vue :value="pixCopiaCola" :size="300" level="H"></qrcode-vue>
            </div>

            <hr style="border-top-color: #6c757d; opacity: 0.1;" />

            <p class="card-text" v-if="pixCopiaCola">
              Se preferir, aqui está o código copia e cola:
            </p>

            <div style="text-align: center;">
              <span
                v-tooltip="{
                  content: 'Copiado!',
                  trigger: 'manual',
                  show: showTooltipPixCopiado,
                }"
                v-clipboard="pixCopiaCola"
                @success="showTooltipPixHandler"
                style="cursor: pointer"
                >{{ pixCopiaCola }}</span
              >
              <p class="card-text">
                <em>(clique para copiar)</em>
              </p>
            </div>

            <!-- <small class="form-text text-muted text-right">
              <i>Também enviamos estas informações para o seu e-mail.</i>
            </small> -->
            <br />
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card" style="margin-right: 20px;">
          <div class="card-body">
            <div class="text-center">
              <img
                :src="financing.imageURL"
                class="card-img-top"
                :alt="financing.name"
              />
            </div>
            <div class="card-body">
              <h6
                class="card-subtitle text-muted text-center"
                style="font-size: 15px; margin-top: 5px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
              >
                <b>{{ financing.name }}</b>
              </h6>
              <br />
              <div class="alert alert-success" role="alert">
                <h5
                  class="card-subtitle text-muted text-center"
                  style="color: #fff !important; margin-top: 0px;"
                >
                  {{ financing.categoria.name }}
                </h5>
              </div>
            </div>

            <p class="card-text text-right" v-if="!financing.is_empresa">
              <!-- <i
                class="fa fa-user-o"
                aria-hidden="true"
                v-tooltip="'Esta campanha é para uma pessoa física.'"
              ></i> -->
              {{ financing.users.created_by.nome }}
            </p>
            <p class="card-text text-right" v-if="financing.is_empresa">
              <!-- <i
                class="fa fa-heart-o"
                aria-hidden="true"
                v-tooltip="
                  'Esta campanha é para uma entidade sem fins lucrativos.'
                "
              ></i> -->
              {{ financing.dados_complementares.empresa.nome }}
            </p>
            <p class="card-text text-right" style="font-size: 12px;">
              {{ financing.cidade.nome }} / {{ financing.estado.name }}
            </p>

            <hr style="border-top-color: #6c757d; opacity: 0.1;" />

            <p
              class="card-text text-left text-muted"
              style="text-transform: uppercase;"
            >
              Valor Já Reservado
              <span style="font-weight: bolder;">{{
                financing.totais.valor_total_formatado
              }}</span>
            </p>

            <div
              class="progress"
              style="height: 1.5rem; background-color: #e9ecef; border-radius: 0.25rem; -webkit-box-shadow: none; box-shadow: none; margin-top: 10px; margin-bottom: 10px;"
            >
              <div
                class="progress-bar bg-info"
                role="progressbar"
                :style="'width: ' + financing.totais.porcentagem_total + '%;'"
                :aria-valuenow="financing.totais.porcentagem_total"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
              <span
                v-if="financing.totais.porcentagem_total >= 15"
                style="margin-top: 13px; margin-left: -50px; color: #fff;"
                >{{ financing.totais.porcentagem_total_formatado }}</span
              >
              <span
                v-if="financing.totais.porcentagem_total < 15"
                style="margin-top: 13px; color: #000;"
                >{{ financing.totais.porcentagem_total_formatado }}</span
              >
            </div>

            <p class="card-text text-right text-muted">
              Alvo
              <span style="font-weight: bolder;">{{
                financing.totais.valor_meta_formatado
              }}</span>
            </p>

            <p v-if="financing.diasRestante" class="card-text text-right">
              <small class="text-muted">
                <i class="fa fa-clock-o" aria-hidden="true"></i>
                {{ financing.diasRestante }} dias restantes
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card {
  padding: 30px 18px;
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1);
  background: #f5f6fa;
  border: 0;
  margin-top: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  border-radius: 0.2857rem;
}
.card-img-top {
  width: 512px;
  height: 342px;
  border-top-left-radius: calc(0.2857rem - 0.0625rem);
  border-top-right-radius: calc(0.2857rem - 0.0625rem);
  text-align: center;
}

img {
  border-radius: 0.2857rem;
  vertical-align: middle;
  border-style: none;
  max-width: 500px;
}

.card .card-body {
  padding: 15px;
}

h6,
.h6 {
  text-transform: uppercase;
  font-weight: 500;
}

.alert.alert-success {
  background-color: #00bf9a;
}

.alert {
  border: 0;
  color: #ffffff;
}

.alert {
  position: relative;
  padding: 0.9rem 1.25rem;
  margin-bottom: 1rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.2857rem;
}

h5,
.h5 {
  font-size: 0.8125rem;
}

.card-text {
  color: #333;
  font-weight: 300;
  font-size: 0.875rem;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 5px;
}

/* Change values to match the radius of your form control */
.fix-rounded-right {
  border-top-right-radius: 0.2rem !important;
  border-bottom-right-radius: 0.2rem !important;
}
</style>
