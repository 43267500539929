<script>
import VueHtml2pdf from 'vue-html2pdf';
import VuePDFViewer from 'vue-instant-pdf-viewer';

export default {
  name: 'termo-uso-modelo',
  props: {
    objeto: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      pdfContract: "",
      downloadPDF: false
    };
  },
  computed: {},
  components: {
    VueHtml2pdf,
    'vue-pdf-viewer': VuePDFViewer,
  },
  methods: {
    generateTermoUsoPdf() {
      this.isLoading = true;
      this.$refs.html2Pdf.generatePdf();
    },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
        let that = this;

        var optionsPdf = options;
        optionsPdf.enableLinks = true;
        optionsPdf.margin = [ 1.1, 0, 1, 0 ];
        optionsPdf.pageBreak= {mode: ['avoid-all', 'css']};
        await html2pdf().set(optionsPdf).from(pdfContent).toPdf().get('pdf').then((pdf) => {
            const totalPages = pdf.internal.getNumberOfPages()
            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                pdf.setFontSize(10);
                pdf.setTextColor(150);

                let coordX = (pdf.internal.pageSize.getWidth() * 0.88);
                let coordY = (pdf.internal.pageSize.getHeight() - 0.3);
                pdf.text('Pág.: ' + i + ' de ' + totalPages, coordX, coordY);
            }
        }).output('datauristring').then(function (pdfAsString) {
            that.$emit('uploadTermoUso_ADMIN', {
                pdfString: pdfAsString,
                order: that.objeto.order
            });
        });
        this.isLoading = false;
    }
  },
  mounted() {
    // this.generateTermoUsoPdf();
  }
};
</script>

<template>
    <div>
        <vue-pdf-viewer
          width="100%"
          url="https://storage.googleapis.com/public-documentos-institucionais/Termo%20de%20Cie%CC%82ncia%20de%20Risco.pdf"
        ></vue-pdf-viewer>

        <!-- <vue-html2pdf
            :show-layout="false"
            :float-layout="false"
            :enable-download="false"
            :preview-modal="false"
            filename="Termo de Uso"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a3"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            @beforeDownload="beforeDownload($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content" style="padding: 40px;">
                <div class="row">
                    <div class="col-md-12" style="padding-top: 40px; padding-bottom: 40px; font-weight: 600;">
                        Termo de Ciência de Risco
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        A Estrutura
                        <br />
                        <br />
                        1. A estruturação do negócio ocorrerá em três dimensões:
                        <br />
                        (1) a captação do recurso do Investidor através da Operação;
                        <br />
                        (2) a operação por meio da emissão de uma CCB pelo Tomador, em favor da Instituição Financeira ou assinatura diretamente ao Investidor e;
                        <br />
                        (3 – se houver) a emissão de um CDBV ou endosso uma CCB em favor do Investidor pela Instituição Financeira.
                        <br />
                        <br />
                        1.1  O Investimento é realizado por pagamento de um boleto bancário,
                    </div>
                </div>

                <br />
                <br />

                <div class="row">
                    <div class="col-md-12">
                        Processo de Investimento
                        <br />
                        <br />
                        Deverão ser consideradas como oportunidades de investimento, para os fins destes Termos de Uso, por meio da Plataforma, a oferta pública de investimento automaticamente dispensada de registro perante a CVM; e as operações de distribuição de CCBs endossadas ou CDBVs realizadas como correspondente bancário pela Plataforma.
                        <br />
                        <br />
                        Um investidor deve atender aos seguintes critérios para poder utilizar a Plataforma AJX Capital Investimento S/A
                        <br />
                        (i) ser uma pessoa física ou jurídica com sede no Brasil;
                        <br />
                        (ii) no caso de pessoas físicas, ter mais de 18 anos, ter residência permanente no Brasil e estar devidamente cadastrado no Cadastro de Pessoas Físicas (CPF);
                        <br />
                        (iii) no caso de pessoas jurídicas, ter sede no Brasil, estar devidamente cadastrado no Cadastro Nacional de Pessoas (CNPJ) e ser representado por pessoas autorizadas e com poderes para agir em seu nome, tais como sócios, diretores ou procuradores;
                        <br />
                        (iv) disponibilizar os dados de uma conta bancária no Brasil para fazer os aportes relacionados com a Plataforma AJX;
                        <br />
                        (v) atender a critérios mínimos de risco de prevenção à fraude, conforme determinados pela política da AJX Capital S/A
                    </div>
                </div>

                <br />
                <br />

                <div class="row">
                    <div class="col-md-12">
                        Riscos da Operação
                        <br />
                        <br />
                        Pelo presente termo de adesão e ciência de risco, confirmo que recebi, no ato do investimento, o prospecto, tive acesso as Informações Complementares, estou ciente dos riscos envolvidos e da sua política de administração e que:
                        <br />
                        A) A CCB possui um percentual prefixado onde e garantido os juros remuneratórios pelo emissor ao investidor.
                        <br />
                        B) Estou ciente dos riscos a que a CCB está exposta, de modo resumido, são:
                        <br />
                        (i) Risco de Mercado
                        <br />
                        (ii)Risco de Crédito
                        <br />
                        (iii) Risco de Liquidez: existe uma carência pré-determinada no produto para resgate do valor aportado.
                        <br />
                        (C) Tenho ciência que este produto se enquadra ao meu perfil de investimento, não alterando minha tolerância aos riscos expostos acima (Mercado, Crédito e Liquidez)
                    </div>
                </div>
                
                <br><br>

                <div class="row">
                    <div class="col-md-12">
                        <b>INVESTIDOR -</b> {{ objeto.investidor_nome }}, Documento: {{ objeto.investidor_cpf }} em Data {{ objeto.data }} Hora {{ objeto.hora }} Email: {{ objeto.investidor_email }} IP: {{ objeto.investidor_ip }} - Hash: {{ objeto.hash_assinatura }}
                    </div>
                </div>
            </section>
        </vue-html2pdf> -->
    </div>
</template>