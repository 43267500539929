<script>
import financingService from '@/services/Financing/financingService';
import Multiselect from 'vue-multiselect';

import { DoubleBounce } from 'vue-loading-spinner';

import Vue from 'vue';
import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

import { VTooltip } from 'v-tooltip';
Vue.directive('tooltip', VTooltip);

import { CWLStatusCampanha } from '@/store/cwl_enums';

import { Orders } from '@/firebase/index';

export default {
  components: {
    DoubleBounce,
    Multiselect
  },
  mounted() {
    this.getFinancings();

    if ( this.modalidade == 'equity' ) {
      const ofertaStore = this.$store.getters.getOferta;
      if ( ofertaStore ) {
        const ofertaStoreId = ofertaStore.oferta_id;
        if ( ofertaStoreId ) {
          this.$router.push(`/project-detail/${ofertaStoreId}`);
        }
      }
    }
  },
  data() {
    return {
      projectsEnv: process.env.VUE_APP_PROJECT_CARD,
      showDisclaimer: process.env.VUE_APP_DISCLAIMER != 'off',
      financings: [],
      isLoading: true,
      searchBar: '',
      selectedStatus: [{ name: 'Em Andamento', code: 'em-andamento' }],
      options: [
        // { name: 'Em criação', code: 'em-criacao' },
        // { name: 'Aguardando análise', code: 'aguardando-analise' },
        // { name: 'Em análise', code: 'em-analise' },
        // { name: 'Aprovada', code: 'aprovada' },
        // { name: 'Reprovada', code: 'reprovada' },
        { name: 'Em Andamento', code: 'em-andamento' },
        { name: 'Concluída com Sucesso', code: 'concluida-com-sucesso' },
        { name: 'Concluída sem Sucesso', code: 'concluida-sem-sucesso' },
        { name: 'Removida do Ar', code: 'removida-do-ar' },
        { name: 'Suspensa', code: 'suspensa' },
        { name: 'Cancelada', code: 'cancelada' }
      ],
      hasMinimalCatchment: false,
      modalidade: process.env.VUE_APP_CHECKOUT,
    };
  },
  computed: {
    filteredData() {
      const filteredItems = [];

      if (this.selectedStatus.length !== 0) {
        this.financings.filter(financing => {
          this.selectedStatus.map(status => {
            if (financing.status === status.code) filteredItems.push(financing);
          });
        });

        return filteredItems.filter(filtered => {
          return (
            (this.hasMinimalCatchment ? filtered.minimalCapture > 1000 : '') ||
            (filtered.name ? filtered.name : '')
              .toLowerCase()
              .includes(this.searchBar.toLowerCase()) ||
            (filtered.categoria ? filtered.categoria.name : '')
              .toLowerCase()
              .includes(this.searchBar.toLowerCase()) ||
            (filtered.dates ? this.formatDate(filtered.dates.created_at) : '')
              .toLowerCase()
              .includes(this.searchBar.toLowerCase()) ||
            (filtered.status ? this.statusDescription(filtered.status) : '')
              .toLowerCase()
              .includes(this.searchBar.toLowerCase())
          );
        });
      }

      return this.financings.filter(financing => {
        return (
          (this.hasMinimalCatchment ? financing.minimalCapture > 1000 : '') ||
          (financing.name ? financing.name : '')
            .toLowerCase()
            .includes(this.searchBar.toLowerCase()) ||
          (financing.categoria ? financing.categoria.name : '')
            .toLowerCase()
            .includes(this.searchBar.toLowerCase()) ||
          (financing.dates ? this.formatDate(financing.dates.created_at) : '')
            .toLowerCase()
            .includes(this.searchBar.toLowerCase()) ||
          (financing.status ? this.statusDescription(financing.status) : '')
            .toLowerCase()
            .includes(this.searchBar.toLowerCase())
        );
      });
    }
  },
  methods: {
    async getFinancings() {
      this.financings = [];
      const financings = await financingService.findAll();
      this.financings = financings.filter(e => e.status === CWLStatusCampanha.EmAndamento || e.status === CWLStatusCampanha.RemovidaDoAr || e.status === CWLStatusCampanha.ConcluidaComSucesso || e.status === CWLStatusCampanha.ConcluidaSemSucesso || e.status === CWLStatusCampanha.Suspensa || e.status === CWLStatusCampanha.Cancelada);
      this.financings.forEach(e => {
        this.calculosDaCampanha(e);
      });
      this.isLoading = false;
    },
    statusDescription(status) {
      return this.$CWLFinancingStatus(status);
    },
    async calculosDaCampanha(financ) {
      const ordersFromFirebase = await Orders.where('financing_id', '==', financ.id).get();
      return this.$CWLCampanhaCalculosOrdens(financ, ordersFromFirebase);
    },
    formatDate(date) {
      return this.$CWLFormatDate(date);
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
    }
  }
};
</script>

<template>
  <div class="projects">
    <div class="projects-texts" v-if="modalidade == 'doacao'">
      <h2 class="projects-title">Projetos para doar</h2>
      <p class="projects-description">
        Conheça mais sobre os projetos que estão recebendo doações
      </p>
    </div>

    <div class="projects-texts" v-if="modalidade == 'equity'">
      <h2 class="projects-title">Projetos para investir</h2>
      <p class="projects-description">
        Conheça mais sobre os projetos que estão recebendo investimentos
      </p>
    </div>

    <div class="projects-list">
      <div class="projects-search-bar">
        <label class="search-bar-label">Pesquisar</label>

        <div class="input-row">
          <input
            v-model="searchBar"
            type="text"
            class="search-bar"
            placeholder="Para pesquisar, digite sua busca aqui..."
          />

          <div class="right-filters">
            <multiselect
              class="status-multiselect"
              v-model="selectedStatus"
              tag-placeholder="Adicione uma nova tag"
              placeholder="Digite ou selecione um status.."
              selectLabel=""
              selectedLabel="Selecionado"
              deselectLabel="Remover"
              label="name"
              track-by="code"
              :options="options"
              :multiple="true"
              :taggable="false"
              @tag="addTag"
            ></multiselect>

            <b-form-checkbox
              v-model="hasMinimalCatchment"
              class="checkbox-flag"
              v-if="modalidade == 'doacao'"
            >
              Campanhas que atingiram a captação mínima
            </b-form-checkbox>

            <b-form-checkbox
              v-model="hasMinimalCatchment"
              class="checkbox-flag"
              v-if="modalidade == 'equity'"
            >
              Ofertas que atingiram a captação mínima
            </b-form-checkbox>
          </div>
        </div>
      </div>

      <double-bounce
        v-if="isLoading"
        style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 25px;"
      ></double-bounce>

      <div class="row">
        <div
          class="col-md-3"
          v-for="(financing, index) in filteredData"
          :key="index"
        >
          <router-link :to="`${projectsEnv}/${financing.id}`" tag="div">
            <div class="card" style="cursor: pointer;">
              <img
                :src="financing.imageURL"
                class="card-img-top"
                :alt="financing.name"
              />
              <div class="card-body">
                <h6
                  class="card-subtitle text-muted text-center"
                  style="font-size: 15px; margin-top: 5px; overflow: hidden;"
                >
                  <b>{{ financing.name }}</b>
                </h6>
                <br />
                <div class="alert alert-dark" role="alert">
                  <h5
                    class="card-subtitle text-muted text-center"
                    style="margin-top: 0px;"
                  >
                    {{ financing.categoria.name }}
                  </h5>
                </div>

                <p class="card-text text-right" v-if="!financing.is_empresa">
                  <i
                    class="fa fa-user-o"
                    aria-hidden="true"
                    v-tooltip="'Esta campanha é para uma pessoa física.'"
                  ></i>
                  {{ financing.users.created_by.nome }}
                </p>
                <p class="card-text text-right" v-if="financing.is_empresa">
                  <i
                    class="fa fa-heart-o"
                    aria-hidden="true"
                    v-tooltip="
                      'Esta campanha é para uma entidade sem fins lucrativos.'
                    "
                  ></i>
                  {{ financing.dados_complementares.empresa.nome }}
                </p>
                <p class="card-text text-right" style="font-size: 12px;">
                  {{ financing.cidade.nome }} / {{ financing.estado.name }}
                </p>

                <hr style="border-top-color: #6c757d; opacity: 0.1;" />

                <p
                  class="card-text text-left text-muted"
                  style="text-transform: uppercase;"
                >
                  Valor arrecadado
                  <span style="font-weight: bolder;">{{
                    financing.totais.valor_total_formatado
                  }}</span>
                </p>

                <div
                  class="progress"
                  style="height: 1.5rem; background-color: #e9ecef; border-radius: 0.25rem; -webkit-box-shadow: none; box-shadow: none; margin-top: 10px; margin-bottom: 10px;"
                >
                  <div
                    v-if="financing.minimalCapture"
                    class="goal"
                    alt="Meta mínima"
                    :title="'Meta mínima ' + financing.totais.valor_meta_minima_formatado"
                    v-bind:style="{
                      marginLeft:
                        'calc(' +
                        (financing.minimalCapture * 100) /
                          financing.fields['Valor'].value +
                        '% - 40px)',
                      marginTop: '2px',
                      zIndex: '9999',
                      position: 'absolute'
                    }"
                  >
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </div>
                  
                  <div
                    v-if="financing.totais.porcentagem_total < 6"
                    class="progress-bar bg-info"
                    role="progressbar"
                    :style="
                      'color: #000; width: ' +
                        financing.totais.porcentagem_total +
                        '%;'
                    "
                    :aria-valuenow="financing.totais.porcentagem_total"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {{ financing.totais.porcentagem_total_formatado }}
                  </div>
                  <div
                    v-if="financing.totais.porcentagem_total >= 6"
                    class="progress-bar bg-info"
                    role="progressbar"
                    :style="
                      'color: #fff; width: ' +
                        financing.totais.porcentagem_total +
                        '%;'
                    "
                    :aria-valuenow="financing.totais.porcentagem_total"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {{ financing.totais.porcentagem_total_formatado }}
                  </div>

                </div>

                <p class="card-text text-right text-muted">
                  Meta
                  <span style="font-weight: bolder;">{{
                    financing.totais.valor_meta_formatado
                  }}</span>
                </p>

                <p v-if="financing.diasRestante" class="card-text text-right">
                  <small class="text-muted">
                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                    {{ financing.diasRestante }} dias restantes
                  </small>
                </p>
              </div>
            </div>
          </router-link>
        </div>

        <div class="empty-data" v-if="!filteredData.length">
          <h1>Nenhum projeto disponível com este filtro...</h1>
        </div>
      </div>

      <br />

      <div class="row" v-if="showDisclaimer">
        <p>
          As sociedades empresárias de pequeno porte e as ofertas apresentadas nesta plataforma estão automaticamente dispensadas de registro pela Comissão de Valores Mobiliários - CVM. A CVM não analisa previamente as ofertas. As ofertas realizadas não implicam por parte da CVM a garantia da veracidade das informações prestadas, de adequação à legislação vigente ou julgamento sobre a qualidade da sociedade empresária de pequeno porte. Antes de aceitar uma oferta leia com atenção as informações essenciais da oferta, em especial a seção de alertas sobre riscos.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card {
  padding: 10px 18px;
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1);
  background: #f5f6fa;
  border: 0;
  margin: 2px;
  margin-top: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  border-radius: 0.2857rem;
}
.card-img-top {
  width: 512px;
  height: 342px;
  border-top-left-radius: calc(0.2857rem - 0.0625rem);
  border-top-right-radius: calc(0.2857rem - 0.0625rem);
}

img {
  max-width: 100%;
  border-radius: 0.2857rem;
  vertical-align: middle;
  border-style: none;
  max-height: 200px !important;
  width: auto !important;
}

.card .card-body {
  padding: 15px;
}

h6,
.h6 {
  text-transform: uppercase;
  font-weight: 500;
}

.alert.alert-success {
  background-color: #00bf9a;
}

.alert {
  border: 0;
  color: #ffffff;
}

.alert {
  position: relative;
  padding: 0.9rem 1.25rem;
  margin-bottom: 1rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.2857rem;
}

h5,
.h5 {
  font-size: 0.8125rem;
}

.card-text {
  color: #333;
  font-weight: 300;
  font-size: 0.875rem;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 5px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
