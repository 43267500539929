<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import financingService from '@/services/Financing/financingService';
import pessoasService from '@/services/Pessoas/pessoasService';
import dynamicFieldsService from '@/services/DynamicFields/dynamicFieldsService';
import getFinancingSummary from '@/services/Functions/getFinancingSummary';

import Vue from 'vue';

import {
  PessoaCaracteristica,
  CWLStatusInvestidor
} from '@/store/cwl_enums';

import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

import { VTooltip } from 'v-tooltip';
Vue.directive('tooltip', VTooltip);

import logsService from '@/services/Logs/logsService';

import { formatCurrency } from '@/formatter/NumberFormatter';

import Simulador from '../components/simulador/Simulador.vue';

import settingsService from '@/services/Settings/settingsService';

export default {
  components: {Simulador},
  data() {
    return {
      financing: {
        totais: {},
      },
      summary: {},
      isLoading: true,
      error: undefined,
      envPath: `${process.env.VUE_APP_ROOT_PORTAL}login`,
      canInvest: true,

      settings: {},

      titleButtonContribuir: 'Contribuir',

      checkoutType: process.env.VUE_APP_CHECKOUT,

      cepForSearch: '',
      skipCepSearch: false,
      isLoadingEnderecoPessoa: false,
      sortedTopics: [],
      financingNameForSimulator: '',
      financingRate: undefined,
      prazo: 12,
      taxaJurosAno: 0,
    };
  },

  computed: {
    user() {
      return this.$store.getters.getPessoa;
    },
    userId() {
      const user = this.$store.getters.getPessoa;
      return user ? user.pessoa_id : undefined;
    },
    address() {
      let address =
        typeof this.financing.cidade === 'string'
          ? this.financing.cidade
          : this.financing.cidade.nome || '';

      if (address) address += ' / ';

      address += this.financing.estado.name;

      return address;
    },
    financingId() {
      return this.$route.params.id;
    },
    totalFormatted() {
      const values = this.summary.values;
      if (!values) return '';
      return formatCurrency(values.total);
    },
    goalFormatted() {
      const values = this.summary.values;
      if (!values) return '';
      return formatCurrency(values.goal);
    },
    percentage() {
      const values = this.summary.values;
      if (!values) return '';
      return parseFloat(values.percentage * 100).toFixed(0);
    },
    minimalCaptureFormatted() {
      return formatCurrency(this.summary.values.minimalCapture);
    },
  },
  async mounted() {
    try {
      let userClaim = this.$store.getters.userClaim;
      if ( userClaim && userClaim.email ) {
        let pessoa = await pessoasService.findByEmail(userClaim.email);
        if ( pessoa ) {
          let pessoaCookie = this.$CWLCreatePessoaForCookie(pessoa);
          this.$store.commit('SET_PESSOA', pessoaCookie);
        }
      }
      this.$store.commit('SET_OFERTA_ID_TEMP', '');
      this.financing = this.$CWLNewFinanciamentoPadrao();
      await this.loadFinancing();
      await this.mountSortedTopics();
    } catch (e) {
      // console.error('project detail init error', e);
    }
    this.isLoading = false;
  },
  methods: {
    formatCurrency,
    async mountSortedTopics() {
      let dynamicFields = await dynamicFieldsService.getAllPublicWithKeys();
      
      let sorted = Object.keys(dynamicFields)
        .map(t => {
          if ( this.financing.fields[t] ) {
            this.$set(this.financing.fields[t], 'key', t);
            return this.financing.fields[t];
          }
        })
        .sort(this.sortProperty('ordenacao'));
      this.sortedTopics = sorted.filter( df => df != undefined);
    },
    sortProperty(prop) {
      return (a, b) => {
        if (a[prop] < b[prop]) return -1;
        if (a[prop] > b[prop]) return 1;
        return 0;
      };
    },
    redirectToLogin() {
      // const id = this.$route.params.id;
      const portalUrl = process.env.VUE_APP_ROOT_PORTAL_SINGIN;
      window.top.location.href = portalUrl // + '?ref=site&id=' + id;
    },
    redirectToProfile() {
      const portalUrl = process.env.VUE_APP_ROOT_PORTAL_SINGIN;
      window.top.location.href = portalUrl // + '?ref=site&gotoprofile=1';
    },
    async loadFinancing() {
      let settings = await settingsService.getPrivateSettings();

      const id = this.financingId;
      this.financing = await financingService.findById(id);
      this.summary = await getFinancingSummary(id);

      if ( this.financing.fields['prazo_oferta'] ) {
        if ( this.financing.fields['taxa_juros'] ) {
          const taxaJurosString = this.financing.fields['taxa_juros'].value;
          const taxaJuros = Number(taxaJurosString);
          this.taxaJurosAno = (taxaJuros * 12);
          
          const financingNameComplemento = settings.platformName;

          this.prazo = Number(this.financing.fields['prazo_oferta'].value);
          this.financingNameForSimulator = financingNameComplemento;
          this.financingRate = taxaJuros;
        }
      }
      console.log('Taxa', this.financingRate)
    },

    async goToCheckout() {
      if (this.financing.status != 'em-andamento') {
        return false;
      }

      const logUser = {
        user: {
          email:
            this.currentPessoa && this.currentPessoa.email
              ? this.currentPessoa.email.email
              : '',
        },
      };
      const logContent = this.financing;
      logsService.createNewLog(
        logUser,
        '',
        'Detalhe Campanha - Investir',
        '',
        logContent,
      );

      this.$store.commit('SET_OFERTA_ID_TEMP', this.financing.id);
      this.$store.commit('SET_PAYMENT_CHECKOUT', {
        financingId: this.financing.id,
      });

      const userId = this.userId;
      if (userId) {
        // console.log(this.user);
        if (this.checkoutType === 'equity') {
          if ( this.user.dados_complementares.approvalStatus == CWLStatusInvestidor.EmAnalise ) {
            this.$toasted.show('Cadastro em análise. Por favor, aguarde a aprovação. Você será notificado por e-mail.', {
              icon: 'times',
              className: 'error',
            });
          }
          else if ( this.user.dados_complementares.approvalStatus == CWLStatusInvestidor.Incompleto 
          || this.user.dados_complementares.approvalStatus == CWLStatusInvestidor.Reprovado ) {
            this.$toasted.show('Cadastro precisa ser revisado. Por favor, verifique seus dados cadastrais.', {
              icon: 'times',
              className: 'error',
            });
            this.redirectToProfile();
          }
          // else if ( this.user.dados_complementares.approvalStatus == CWLStatusInvestidor.Aprovado ) {
            this.$router.push(`/new-investment/${this.financing.id}`);
          // }
        } else {
          this.$router.push(`/project/${this.financing.id}/payment-checkout`);
        }
      } else {
        this.$toasted.show('Você precisa estar logado para contribuir', {
          icon: 'times',
          className: 'error',
        });
        this.redirectToLogin();
      }
    },

    async makePayment() {
      this.$CWLVincularCaracteristicaPessoa(
        this.pessoa,
        PessoaCaracteristica.Doador,
        false,
      );
    },
  },
};
</script>

<template>
  <div class="project-detail" style="overflow-x: hidden;">
    <div class="row" v-if="financing.id">
      <div class="col-md-8">
        <div class="card" style="margin-left: 10px; margin-right: 10px;">
          <div class="text-center">
            <img
              :src="financing.imageURL"
              class="card-img-top"
              :alt="financing.name"
            />
          </div>
          <div class="card-body">
            <h6
              class="card-subtitle text-muted text-center"
              style="font-size: 15px; margin-top: 5px; overflow: hidden;"
            >
              <b>{{ financing.name }}</b>
            </h6>
            <br />
            <div class="alert alert-dark" role="alert">
              <h5
                class="card-subtitle text-muted text-center"
                style="margin-top: 0px;"
              >
                {{ financing.categoria.name }}
              </h5>
            </div>
            <br />

            <div
              v-if="financing.content"
              class="ql-container ql-snow"
              style="border: 0px !important;"
            >
              <div class="ql-editor">
                <div v-html="financing.content"></div>
              </div>
            </div>

            <p v-if="financing.balancete" class="balancete-title">
              <span class="balancete-description">
                Link de acesso ao Plano de Aplicação/Prestação de Contas
                <a
                  :href="financing.balancete"
                  class="balancete-link"
                  target="_blank"
                  >Clique aqui</a
                >.
              </span>
            </p>
          </div>
        </div>

        <simulador v-if="financingRate" :financingName="financingNameForSimulator" :rate="financingRate" :taxaJurosAno="taxaJurosAno" :numOfPeriod="prazo" />
      </div>

      <div class="col-md-4">
        <div class="card" style="margin-right: 10px; margin-left: 10px;">
          <div class="card-body">
            <div v-if="financing.status == 'em-andamento'">
              <h6
                class="card-subtitle text-muted text-center"
                style="font-size: 15px; margin-top: 5px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
              >
                <b v-if="checkoutType == 'doacao'">CONTRIBUA COM ESTA CAUSA</b>
                <b v-if="checkoutType == 'equity'">INVISTA NESTA EMPRESA</b>
              </h6>
              <br />

              <div
                class="alert alert-success"
                role="alert"
                style="cursor: pointer; background-color: #17a2b8"
                @click="goToCheckout"
              >
                <h5
                  class="card-subtitle text-muted text-center"
                  style="color: #fff !important; margin-top: 0px;"
                  v-if="checkoutType == 'doacao'"
                >
                  $ CONTRIBUIR
                </h5>

                <h5
                  class="card-subtitle text-muted text-center"
                  style="color: #e4e6ed !important; margin-top: 0px;"
                  v-if="checkoutType == 'equity'"
                >
                  $ INVESTIR
                </h5>
              </div>
            </div>

            <div v-if="financing.status != 'em-andamento'">
              <div class="alert alert-danger" role="alert">
                <h5
                  class="card-subtitle text-muted text-center"
                  v-if="checkoutType == 'doacao'"
                >
                  Campanha
                  <strong>{{ $CWLFinancingStatus(financing.status) }}</strong
                  >! Não é possível contribuir.
                </h5>

                <h5
                  class="card-subtitle text-muted text-center"
                  v-if="checkoutType == 'equity'"
                >
                  Oferta
                  <strong>{{ $CWLFinancingStatus(financing.status) }}</strong
                  >! Não é possível investir.
                </h5>
              </div>
            </div>

            <br />

            <p class="card-text text-right" v-if="!financing.is_empresa">
              <i
                class="fa fa-user-o"
                aria-hidden="true"
                v-tooltip="'Esta campanha é para uma pessoa física.'"
                v-if="checkoutType == 'doacao'"
              ></i>
              {{ financing.users.created_by.nome }}
            </p>
            <p class="card-text text-right" v-if="financing.is_empresa">
              <i
                class="fa fa-heart-o"
                aria-hidden="true"
                v-tooltip="
                  'Esta campanha é para uma entidade sem fins lucrativos.'
                "
                v-if="checkoutType == 'doacao'"
              ></i>
              {{ financing.dados_complementares.empresa.nome }}
            </p>
            <p class="card-text text-right" style="font-size: 12px;">
              {{ address }}
            </p>

            <hr style="border-top-color: #6c757d; opacity: 0.1;" />

            <p
              class="card-text text-left text-muted"
              style="text-transform: uppercase;"
            >
              Valor Já Reservado
              <span style="font-weight: bolder;">{{ totalFormatted }}</span>
            </p>

            <div
              class="progress"
              style="height: 1.5rem; background-color: #e9ecef; border-radius: 0.25rem; -webkit-box-shadow: none; box-shadow: none; margin-top: 10px; margin-bottom: 10px;"
            >
              <div
                class="progress-bar bg-info"
                role="progressbar"
                :style="'width: ' + percentage + '%;'"
                :aria-valuenow="percentage"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {{ percentage }} %
              </div>
              <!-- <div
                v-if="summary.values && summary.values.minimalCapture"
                class="goal"
                :title="'Meta mínima ' + minimalCaptureFormatted"
                v-bind:style="{
                  marginLeft:
                    'calc(' +
                    (summary.values.minimalCapture * 100) /
                      summary.values.goal +
                    '% - 40px)',
                  marginTop: '2px',
                  zIndex: '9999',
                  position: 'absolute',
                }"
              >
                <i class="fa fa-check" aria-hidden="true"></i>
              </div> -->
            </div>

            <p class="card-text text-right text-muted">
              Alvo
              <span style="font-weight: bolder;">{{ goalFormatted }}</span>
            </p>

            <p v-if="summary.dates" class="card-text text-right">
              <small class="text-muted">
                <i class="fa fa-clock-o" aria-hidden="true"></i>
                {{ summary.dates.daysRemaining }} dias restantes
              </small>
            </p>
            <div v-if="checkoutType == 'equity'">
              <div
                class="col-md-12"
                v-for="field in sortedTopics"
                :key="field.id"
                style="text-transform: uppercase; color: #6c757d !important; font-weight: 300 !important; font-size: 0.875rem !important; font-family: 'Poppins', sans-serif !important; margin-bottom: 15px;"
              >
                <label>{{ field.label }}</label>
                <currency-input
                  v-if="field.type == 'currency'"
                  :id="field.id"
                  class="form-control"
                  v-model="field.value"
                  :placeholder="field.placeholder"
                  :required="field.required"
                  currency="BRL"
                  locale="pt-br"
                  :auto-decimal-mode="true"
                  :distraction-free="false"
                  readonly
                  disabled
                  style="color: #6c757d !important; font-weight: 300 !important; font-size: 0.875rem !important; font-family: 'Poppins', sans-serif !important;"
                />
                <input
                  v-if="
                    field.type != 'currency' &&
                      field.type != 'percent' &&
                      field.reference != 'Encerramento da Oferta'
                  "
                  :type="field.type"
                  v-model="field.value"
                  readonly
                  disabled
                  class="form-control"
                  style="color: #6c757d !important; font-weight: 300 !important; font-size: 0.875rem !important; font-family: 'Poppins', sans-serif !important;"
                />
                <input
                  v-if="field.type != 'currency' && field.type == 'percent'"
                  :type="field.type"
                  v-model="field.value"
                  v-mask="['#%', '##%', '###%']"
                  readonly
                  disabled
                  class="form-control"
                  style="color: #6c757d !important; font-weight: 300 !important; font-size: 0.875rem !important; font-family: 'Poppins', sans-serif !important;"
                />
                <input
                  v-if="field.reference == 'Encerramento da Oferta'"
                  :type="field.type"
                  v-model="field.value"
                  readonly
                  disabled
                  class="form-control"
                  style="color: #6c757d !important; font-weight: 300 !important; font-size: 0.875rem !important; font-family: 'Poppins', sans-serif !important;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card {
  padding: 30px 18px;
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1);
  background: #f5f6fa;
  border: 0;
  margin-top: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  border-radius: 0.2857rem;
}
.card-img-top {
  width: 512px;
  height: 342px;
  border-top-left-radius: calc(0.2857rem - 0.0625rem);
  border-top-right-radius: calc(0.2857rem - 0.0625rem);
  text-align: center;
}

img {
  border-radius: 0.2857rem;
  vertical-align: middle;
  border-style: none;
  max-width: 500px;
}

.card .card-body {
  padding: 15px;
}

h6,
.h6 {
  text-transform: uppercase;
  font-weight: 500;
}

div.card-body .alert.alert-success {
  background-color: #0006b4 !important;
  color: #e4e6ed;
}

div.card-body .alert-success:hover {
  background-color: #00035a !important;
  color: #e4e6ed;
}

div.card-body .bg-info {
  background-color: #0006b4 !important;
}

.alert {
  border: 0;
}

.alert {
  position: relative;
  padding: 0.9rem 1.25rem;
  margin-bottom: 1rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.2857rem;
}

h5,
.h5 {
  font-size: 0.8125rem;
}

.card-text {
  color: #333;
  font-weight: 300;
  font-size: 0.875rem;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 5px;
}
.card-img-top {
    width: 100%!important;
    height: auto!important;   
}
/* Change values to match the radius of your form control */
.fix-rounded-right {
  border-top-right-radius: 0.2rem !important;
  border-bottom-right-radius: 0.2rem !important;
}
</style>
