import { firestore, functions } from 'firebase/app';
const axios = require('axios');
// const moment = require('moment');

import { Storage } from '@/firebase/index';
import firebase from 'firebase';

export default {
  _ref() {
    return firestore().collection('bank-slip');
  },

  storageRef(path) {
    return firebase.storage().ref(path);
  },

  async urlAttachment(file, orderId) {
    const fileNameAdjusted = orderId.replace(/\//g, '_');
    const storageEnvironment = Storage.ref(`BoletosOrders/${fileNameAdjusted}`);
    let url = await storageEnvironment
      .child('Boleto_' + fileNameAdjusted + '.pdf')
      .getDownloadURL();

    console.log(url);

    return url;
  },

  async addNewAttachment(attachment, orderId) {
    const fileNameAdjusted = orderId.replace(/\//g, '_');

    const storageRef = this.storageRef(`BoletosOrders/${fileNameAdjusted}`);

    var task = await storageRef
      .child(`Boleto_${fileNameAdjusted}.pdf`)
      .putString(attachment, 'base64', { contentType: 'application/pdf' });

    console.log(task);

    return task;
  },

  async processPayment(data) {
    console.debug('payment request', data);
    const processPaymentFunction = functions().httpsCallable(
      'processPaymentCall',
    );

    try {
      const response = await processPaymentFunction(data);
      console.debug('payment response', response);
      if (this.hasError(response)) {
        const message = this.handleErrorMessage(response);
        console.warn('payment error message', message);
        throw new Error(message);
      }
      return response.data;
    } catch (error) {
      console.log('payment error', error);
      throw error;
    }
  },

  async sendWhatsCCBEmitido(orderId) {
    const endpoint =
      process.env.VUE_APP_ENDPOINT_WS + 'whatsTriggerContratoEmitido';
    await axios.post(endpoint, {
      orderId: orderId,
    });
  },

  async signCCBEmitido(orderId, ccb) {
    const endpoint =
      process.env.VUE_APP_ENDPOINT_WS + 'signContratoCCBAfterPayment';
    await axios.post(endpoint, {
      ccbNumero: ccb,
      orderId: orderId,
    });
  },

  sendMailFinalizarCompraPIX(orderId) {
    const endpoint =
      process.env.VUE_APP_ENDPOINT_WS + 'sendMailFinalizarCompraPIX';
    console.log('calll');
    axios.post(endpoint, {
      orderId: orderId,
    });
  },

  async checkSameOrders(pessoaId, financingId, value) {
    const endpoint =
      process.env.VUE_APP_ENDPOINT_WS + 'totalOrdersForTodayByPessoa';
    console.log('calll');
    return await axios.post(endpoint, {
      pessoaId: pessoaId,
      financingId: financingId,
      valor: value,
    });
  },

  async addEmailToCronJob(orderId) {
    const endpoint = process.env.VUE_APP_ENDPOINT_WS + 'emsAddEmail';
    await axios.post(endpoint, {
      orderId: orderId,
    });
  },

  async processPaymentINTER(order, type, id) {
    console.log('order', order);
    let url = '';
    let idCorrelacao = order.ccb.simulacao.numero_ccb;
    if (!idCorrelacao) {
      throw 'No ID Correlacao';
    }
    // if(order.id) {
    //   idCorrelacao = order.id;
    // }
    let bankinurl = process.env.VUE_APP_BANKING_URL;
    let webhookstatuschange = process.env.VUE_APP_WEBHOOK_AJX_API_WEBHOOK;
    // let pessoa = order.pessoa;
    // var parsedDate = null;
    // var newDate = null;

    if (!bankinurl) {
      bankinurl = 'https://ajx-payment-gateway-uednubmpia-rj.a.run.app';
      console.log(
        'running on dev mode, please double check if this is desired behaviour if not please define env BANKING_URL',
      );
    }

    if (!webhookstatuschange) {
      webhookstatuschange =
        'https://us-central1-ecwl-ajx-stage.cloudfunctions.net/changeStatusPagamentoWebhook';
      console.log(
        'running on dev mode, please double check if this is desired behaviour if not please define env WEBHOOK_AJX_API_WEBHOOK',
      );
    }

    if(type== 'BOLETO') {
      // let complemento = '';
      // if(pessoa.endereco.complemento) {
      //   complemento = pessoa.endereco.complemento;
      // }
      // let payload = {
      //   "idCorrelacao": idCorrelacao,
      //   "qtdDiasCancelamentoPosVencimento": 3,
      //   "devedor": {
      //     "tipoPessoa": "FISICA",
      //     "nome": pessoa.nome,
      //     "documento": pessoa.documento.numero.split('.').join('').split('-').join(''),
      //     "endereco": pessoa.endereco.rua,
      //     "numero": pessoa.endereco.numero,
      //     "complemento": complemento,
      //     "bairro": pessoa.endereco.bairro,
      //     "cidade": pessoa.endereco.cidade,
      //     "uf": pessoa.endereco.estado.uf,
      //     "cep": pessoa.endereco.cep.replace('-', ''),
      //     "email": pessoa.email.email
      //   },
      //   valor: order.item.value,
      //   textoInformativo: `Pagamento de investimento CCB nº ${order.ccb.simulacao.numero_ccb}`,
      //   urlWebhook: webhookstatuschange,
      // };

      // console.log(payload);

      // //call banking api
      // const { data } = await axios.post(
      //   bankinurl + '/v1/cobrancas/boletos',
      //   payload,
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   },
      // );

      // console.log('Paid with new banking api');
      // console.log(data);

      // parsedDate = new Date(Date.parse(data.dataCriacao));
      // let days_3_seconds = 86400 * 3;
      // newDate = new Date(parsedDate.getTime() + 1000 * days_3_seconds);

      // await this.addNewAttachment(data.pdfBase64, idCorrelacao);
      // url = await this.urlAttachment(idCorrelacao + '.pdf', idCorrelacao);
      // console.log(url);

      // return {
      //   idCorrelacao: idCorrelacao,
      //   IdTransaction: data.id,
      //   DueDate: moment(newDate).format('YYYY/MM/DD H:mi:ss'),
      //   BankSlipUrl: url,
      //   DigitableLine: data.linhaDigitavel,
      //   Token: data.id,
      // };
    }

    if (type == 'PIX') {
      // let payload = {
      //   idCorrelacao: idCorrelacao,
      //   segundosExpiracao: 172800,
      //   devedor: {
      //     tipoPessoa: 'FISICA',
      //     nome: pessoa.nome,
      //     documento: pessoa.documento.numero
      //       .split('.')
      //       .join('')
      //       .split('-')
      //       .join(''),
      //   },
      //   valor: order.item.value,
      //   textoInformativo: `Pagamento de investimento CCB nº ${order.ccb.simulacao.numero_ccb} (AJUSTE TEMPORARIO ENVIANDO PARA TED)`,
      //   urlWebhook: webhookstatuschange,
      // };

      // console.log(payload);

      // //call api to api
      // //TODO: adjust when has new payment gateway
      // const { data } = await axios.post(
      //   bankinurl + '/v1/cobrancas/ted',
      //   payload,
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   },
      // );

      // parsedDate = new Date(Date.parse(data.dataCriacao));
      // newDate = new Date(parsedDate.getTime() + 1000 * 1200);

      // console.log('Paid with new banking api');
      // console.log(data);

      // return {
      //   url: url,
      //   idCorrelacao: idCorrelacao,
      //   IdTransaction: data.id,
      //   expireAt: moment(newDate).format('YYYY/MM/DD H:mi:ss'),
      //   key: data.copiaECola,
      //   qrCode: data.copiaECola,
      // };

      let payload = {
        orderId: id,
        method: 'PIX',
      }

      const { data } = await axios.post(
        process.env.VUE_APP_ENDPOINT_WS + 'payOrder',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      return {
        url: url,
        idCorrelacao: idCorrelacao,
        IdTransaction: data.transacao_id,
        expireAt: data.data_expiracao,
        key: data.numero,
        qrCode: data.numero,
      };
    }

    if (type == 'TED') {
      let payload = {
        idCorrelacao: idCorrelacao,
        valor: order.item.value,
        textoInformativo: `Pagamento de investimento CCB nº ${order.ccb.simulacao.numero_ccb}`,
        orderId: id,
        method: 'TED',
      };

      console.log(payload);

      //call api to api
      const { data } = await axios.post(
        process.env.VUE_APP_ENDPOINT_WS + 'payOrder',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      console.log('Paid with new banking api');
      console.log(data);

      return {
        idCorrelacao: idCorrelacao,
        IdTransaction: data.id,
      };
    }

    return {};
  },

  hasError(response) {
    return (
      (response.data && response.data.error !== undefined) ||
      response.error !== undefined
    );
  },

  handleErrorMessage(response) {
    const error = response.error ?? response.data ? response.data.error : {};
    if (error.code === '019') {
      return 'O CPF informado é inválido. Por favor, forneça um CPF válido';
    } else if (error.message && error.code) {
      return error.message + ' (' + error.code + ')';
    } else if (error.message) {
      return error.message;
    } else {
      return (
        'Ocorreu um erro durante o pagamento, tente novamente.' +
        JSON.stringify()
      );
    }
  },

  async createSlip(slip) {
    await this._ref().add(slip);
  },
};
